div.dt-container .dt-paging .dt-paging-button {
    /* box-sizing: border-box; */
    /* display: inline-block; */
    /* min-width: 1.5em; */
    /* padding: 0.5em 1em; */
    margin: 0px;
    padding: 0;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer !important;
    color: inherit !important;
    border: 1px solid transparent;
    border-radius: 2px;
    background: transparent;
}