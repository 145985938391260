 /* table {
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        padding: 8px;
        border: 1px solid #ddd;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
    } */
/* 
    .datepick .rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-popup-date.rs-picker-popup{
        left : 900px
    } */

    .datepick {
        max-width: 300px; /* Adjust maximum width as needed */
        margin-right: 100px; /* Set margin-right to 100px */
      }


      